import { PoweredLogoWrapper } from './PoweredLogo.css';
import poweredLogo from './assets/img/poweredlogo.png';

export default function PoweredLogo() {
  return (
    <PoweredLogoWrapper
      onClick={() => {
        window.open('https://www.conceptarthouse.com/', '_blank');
      }}
    >
      <h1>Powered by</h1>
      <img src={poweredLogo} alt="cah"></img>
    </PoweredLogoWrapper>
  );
}

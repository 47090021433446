import type { AbstractMesh, AnimationGroup, Scene as SceneType } from '@babylonjs/core';
import { Texture } from '@babylonjs/core';

export type HistoryData = {
  type: string;
  currentDay: number;
  history: number[];
};

export type CalendarMeshType = AbstractMesh & {
  opened: boolean;
};

export type RarityTexture = Texture & {
  rarity: number;
};

export const createTexture = (url: string, scene: SceneType) => {
  const img = document.createElement('img');
  img.src = url;
  return new Texture(img.src, scene, false, false);
};

export const toggleOpened = (mesh: CalendarMeshType) => {
  mesh.opened = !mesh.opened;
};

export const isAnimationInProgress = (animationGroups: AnimationGroup[]): boolean =>
  animationGroups.some((animation) => animation.isPlaying);

export const randomIntFromInteval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export function inRange(x: number, min: number, max: number) {
  return (x - min) * (x - max) <= 0;
}

export const getRandomTextureFromSearchArray = (array: RarityTexture[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

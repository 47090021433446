import styled from 'styled-components/macro';

export const PoweredLogoWrapper = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  h1 {
    font-size: 13px;
    color: white;
    margin-right: 10px;
  }

  img {
    max-width: 70px;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

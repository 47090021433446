import type { ArcRotateCamera } from '@babylonjs/core';
import { Animation, SineEase, EasingFunction } from '@babylonjs/core';

const easingFunction = new SineEase();
easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

const alphaAnimation = new Animation(
  'alphaAnimation',
  'alpha',
  60,
  Animation.ANIMATIONTYPE_FLOAT,
  Animation.ANIMATIONLOOPMODE_CONSTANT,
);

alphaAnimation.setEasingFunction(easingFunction);

const alphaKeys = [];

alphaKeys.push({
  frame: 0,
  value: -Math.PI / 2,
});
alphaKeys.push({
  frame: 100,
  value: Math.PI / 2,
});

alphaAnimation.setKeys(alphaKeys);

const radiusAnimation = new Animation(
  'radiusAnimation',
  'radius',
  60,
  Animation.ANIMATIONTYPE_FLOAT,
  Animation.ANIMATIONLOOPMODE_CONSTANT,
);

radiusAnimation.setEasingFunction(easingFunction);

const radiusKeys = [];

radiusKeys.push({
  frame: 0,
  value: 1000,
});
radiusKeys.push({
  frame: 100,
  value: 60,
});

radiusAnimation.setKeys(radiusKeys);

const getResetCameraAnimations = (camera: ArcRotateCamera): Animation[] => {
  const alphaAnimation = new Animation(
    'resetAlpha',
    'alpha',
    60,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CONSTANT,
  );

  alphaAnimation.setEasingFunction(easingFunction);

  const resetCameraAlphaKeys = [];

  resetCameraAlphaKeys.push({
    frame: 0,
    value: camera.alpha,
  });
  resetCameraAlphaKeys.push({
    frame: 100,
    value: Math.PI / 2,
  });

  alphaAnimation.setKeys(resetCameraAlphaKeys);

  const radiusAnimation = new Animation(
    'resetRadius',
    'radius',
    60,
    Animation.ANIMATIONTYPE_FLOAT,
    Animation.ANIMATIONLOOPMODE_CONSTANT,
  );

  radiusAnimation.setEasingFunction(easingFunction);

  const resetCameraRadiusKeys = [];

  resetCameraRadiusKeys.push({
    frame: 0,
    value: camera.radius,
  });
  resetCameraRadiusKeys.push({
    frame: 100,
    value: 60,
  });

  radiusAnimation.setKeys(resetCameraRadiusKeys);

  return [alphaAnimation, radiusAnimation];
};

export { alphaAnimation, radiusAnimation, getResetCameraAnimations };

/* eslint-disable react/no-unknown-property */
import { Suspense, useEffect, useRef, useState } from 'react';
import type { AbstractMesh, AnimationGroup, ArcRotateCamera, PBRMaterial, Scene as SceneType } from '@babylonjs/core';
import { Texture } from '@babylonjs/core';
import { Vector3, Color3, Color4, ActionManager, ExecuteCodeAction, GlowLayer, CubeTexture } from '@babylonjs/core';
import type { SceneEventArgs } from 'react-babylonjs';
import { Model, Scene } from 'react-babylonjs';
import '@babylonjs/loaders/glTF';

import environmentMap from './assets/textures/studio.env';
import disabledDrawerImg from './assets/textures/MOMO_ALB_G.jpg';
import { alphaAnimation, radiusAnimation, getResetCameraAnimations } from './animations';
import {
  createTexture,
  toggleOpened,
  isAnimationInProgress,
  randomIntFromInteval,
  inRange,
  getRandomTextureFromSearchArray,
} from './utils/utils';
import type { HistoryData, CalendarMeshType, RarityTexture } from './utils/utils';

declare global {
  interface Window {
    raffle: {
      triggerPrompt: () => void;
      getHistory: () => Promise<HistoryData>;
      notifyReady: () => void;
    };
  }
}

const ANIMATION_SPEED_DRAWER = 1;
const ANIMATION_SPEED_CAMERA = 2;

enum RarityValue {
  RARE = 5,
  UNCOMMON = 25,
  COMMON = 75,
}

enum RarityType {
  RARE = 'rare',
  COMMON = 'common',
  UNCOMMON = 'uncommon',
}

enum DrawerState {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  LOCKED = 'locked',
}

const expressionsAndRarity = {
  angry: RarityValue.COMMON,
  annoyed: RarityValue.RARE,
  big_goof: RarityValue.COMMON,
  cute: RarityValue.COMMON,
  frumpy: RarityValue.UNCOMMON,
  glee: RarityValue.COMMON,
  grin_01: RarityValue.COMMON,
  grin_02: RarityValue.UNCOMMON,
  grump: RarityValue.UNCOMMON,
  grumpy: RarityValue.COMMON,
  guffaw: RarityValue.COMMON,
  roar: RarityValue.COMMON,
  shocked: RarityValue.COMMON,
  sleeping: RarityValue.UNCOMMON,
  sly: RarityValue.COMMON,
  smile: RarityValue.COMMON,
  smirk: RarityValue.UNCOMMON,
  smooch: RarityValue.COMMON,
  surprise: RarityValue.UNCOMMON,
  troubled: RarityValue.COMMON,
  uhh: RarityValue.RARE,
  undecided: RarityValue.RARE,
};

type CalendarProps = {
  setOpacity: (value: number) => void;
};

function Calendar({ setOpacity }: CalendarProps) {
  const [scene, setScene] = useState<SceneType>();
  const [loadCalendar, setLoadCalendar] = useState<boolean>(false);
  const [isSceneReady, setIsSceneReady] = useState<boolean>(false);

  const actionManager = useRef<ActionManager>();
  const pickedDownMesh = useRef<CalendarMeshType>();
  const sceneCamera = useRef<ArcRotateCamera>();
  const calendarRoot = useRef<CalendarMeshType>();
  const currentlyActiveAnimation = useRef<AnimationGroup>();
  const faceMaterial = useRef<PBRMaterial>();
  const faceTextures = useRef<RarityTexture[]>();
  const animationFrameID = useRef<number>(0);
  const day = useRef<number>();
  const drawerStatesTextures = useRef<[Texture, Texture]>();

  useEffect(() => {
    if (isSceneReady) {
      setTimeout(() => {
        if (!calendarRoot.current || !scene) return;
        window.raffle.notifyReady();
        calendarRoot.current.setEnabled(true);
        scene.beginAnimation(sceneCamera.current, 0, 100, false, 0.5);
      }, 3000);
    }
  }, [isSceneReady]);

  useEffect(() => {
    if (!scene) return;
    setLoadCalendar(true);
    setHistoryData();
    preloadExpressions();
  }, [scene]);

  const createDrawerStateTextures = (scene: SceneType) => {
    const enabled = scene.getTextureByName('momo (Base Color)');
    const disabled = createTexture(disabledDrawerImg, scene);
    return [enabled, disabled] as [Texture, Texture];
  };

  const setHistoryData = () => {
    const entryHistory = window.raffle.getHistory();
    entryHistory.then((payload: HistoryData) => {
      day.current = payload.currentDay;
    });
  };

  const preloadExpressions = () => {
    const textures = [];
    for (const expression of Object.keys(expressionsAndRarity)) {
      const img = document.createElement('img');
      img.src = `./scenes_assets/Calendar/expressions/${expression}.png`;
      const texture = new Texture(img.src, scene, false, false) as RarityTexture;
      texture.name = `${expression}_texture`;
      texture.rarity = expressionsAndRarity[expression as keyof typeof expressionsAndRarity];
      textures.push(texture);
    }
    faceTextures.current = textures;
  };

  const resetCameraOnDrawerPick = (scene: SceneType) => {
    const camera = sceneCamera.current;
    if (!camera) return;

    camera.animations = [];
    camera.animations.push(...getResetCameraAnimations(camera));

    scene.beginAnimation(camera, 0, 100, false, ANIMATION_SPEED_CAMERA);
  };

  const playOpenAnimation = (animation: AnimationGroup, speed: number, callback: () => void) => {
    animation.goToFrame(animation.from);
    animation.speedRatio = speed;
    animation.play();

    animation.onAnimationEndObservable.addOnce(() => callback());

    currentlyActiveAnimation.current = animation;
  };

  const playCloseAnimation = (animation: AnimationGroup, speed: number, callback: () => void) => {
    animation.goToFrame(animation.to);
    animation.speedRatio = -speed;
    animation.play();

    animation.onAnimationEndObservable.addOnce(() => callback());
  };

  const closeOpenedAnimations = (scene: SceneType) => {
    if (!currentlyActiveAnimation.current) return;
    const mesh = scene.getMeshByName(currentlyActiveAnimation.current.name) as CalendarMeshType;
    if (!mesh.opened) return;
    playCloseAnimation(currentlyActiveAnimation.current, ANIMATION_SPEED_DRAWER, () => {
      toggleOpened(mesh);
      if (!actionManager.current) return;
      mesh.actionManager = actionManager.current;
    });
  };

  const setOvarlayVisibility = (mesh: CalendarMeshType, visible: boolean) => {
    for (const child of mesh.getChildMeshes()) {
      const overlay = child as CalendarMeshType;
      overlay.isVisible = visible;
    }
  };

  const setDrawerState = (mesh: CalendarMeshType, state: DrawerState) => {
    const material = mesh.material as PBRMaterial;
    if (!drawerStatesTextures.current || !actionManager.current) return;
    switch (state) {
      case DrawerState.ENABLED:
        material.albedoTexture = drawerStatesTextures.current[0];
        setOvarlayVisibility(mesh, false);
        mesh.actionManager = actionManager.current;
        break;
      case DrawerState.DISABLED:
        material.albedoTexture = drawerStatesTextures.current[1];
        setOvarlayVisibility(mesh, false);
        mesh.actionManager = null;
        break;
      case DrawerState.LOCKED:
        material.albedoTexture = drawerStatesTextures.current[0];
        setOvarlayVisibility(mesh, true);
        mesh.actionManager = null;
        break;

      default:
        break;
    }
    material.albedoColor = new Color3(0.5, 0.5, 0.5);
  };

  const getExpressionsByRarity = (rarity: RarityType): RarityTexture[] => {
    if (!faceTextures.current) return [];
    const expressions = [];
    for (const expression of faceTextures.current) {
      switch (rarity) {
        case RarityType.RARE:
          if (expression.rarity === RarityValue.RARE) expressions.push(expression);
          break;
        case RarityType.UNCOMMON:
          if (expression.rarity === RarityValue.UNCOMMON) expressions.push(expression);
          break;
        case RarityType.COMMON:
          if (expression.rarity === RarityValue.COMMON) expressions.push(expression);
          break;
        default:
          break;
      }
    }
    return expressions;
  };

  const setSearchArrayByRarity = (targetNumber: number) => {
    let array: RarityTexture[] = [];
    if (inRange(targetNumber, 1, 74)) {
      array = getExpressionsByRarity(RarityType.COMMON);
    } else if (inRange(targetNumber, 75, 94)) {
      array = getExpressionsByRarity(RarityType.UNCOMMON);
    } else if (inRange(targetNumber, 95, 100)) {
      array = getExpressionsByRarity(RarityType.RARE);
    }
    return array;
  };

  const swapExpression = () => {
    const material = faceMaterial.current;
    if (!material || !faceTextures.current) return;

    const randomNumber = randomIntFromInteval(1, 100);
    const searchArray = setSearchArrayByRarity(randomNumber);
    const newExpression = getRandomTextureFromSearchArray(searchArray);

    material.albedoTexture = newExpression;
    material.albedoTexture.hasAlpha = true;
  };

  const getDrawers = (scene: SceneType): CalendarMeshType[] => {
    const drawers = [];
    for (const mesh of scene.meshes) {
      if (mesh.name.includes('drawer')) {
        drawers.push(mesh as CalendarMeshType);
      }
    }
    return drawers;
  };

  const getOverlays = (scene: SceneType): CalendarMeshType[] => {
    const overlays = [];
    for (const mesh of scene.meshes) {
      if (mesh.name.includes('overlay')) {
        overlays.push(mesh as CalendarMeshType);
      }
    }
    return overlays;
  };

  const createDrawerActionManager = (scene: SceneType) => {
    const actionManager = new ActionManager(scene);

    actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPickDownTrigger, function (e) {
        pickedDownMesh.current = e.meshUnderPointer as CalendarMeshType;
      }),
    );

    actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function (e) {
        const pickedMesh = e.meshUnderPointer as CalendarMeshType;
        if (pickedMesh !== pickedDownMesh.current || isAnimationInProgress(scene.animationGroups)) return;

        const animation = scene.getAnimationGroupByName(pickedMesh.name) as AnimationGroup;

        if (!pickedMesh.opened) {
          playOpenAnimation(animation, ANIMATION_SPEED_DRAWER, () => {
            toggleOpened(pickedMesh);
            resetCameraOnDrawerPick(scene);
            cancelAnimationFrame(animationFrameID.current);
            swapExpression();
            pickedMesh.actionManager = null;
            window.raffle.triggerPrompt();
            setTimeout(() => {
              closeOpenedAnimations(scene);
            }, 3000);
          });
        }
      }),
    );

    return actionManager;
  };

  const setupScene = ({ scene }: SceneEventArgs) => {
    scene.clearColor = new Color4(0.043, 0.043, 0.1, 1);
    setScene(scene);
    scene.onReadyObservable.add(() => {
      const envMap = new CubeTexture(environmentMap, scene);
      scene.environmentTexture = envMap;

      const gl = new GlowLayer('glow', scene, {
        blurKernelSize: 64,
      });

      gl.intensity = 0.5;

      setTimeout(() => {
        setOpacity(0);
        setIsSceneReady(true);
      }, 4000);
    });
  };
  const setupCamera = (camera: ArcRotateCamera) => {
    camera.animations.push(alphaAnimation, radiusAnimation);
    sceneCamera.current = camera;
  };

  const setupCalendar = (calendar: CalendarMeshType) => {
    if (!scene) return;
    scene.stopAllAnimations();
    const root = calendar.getChildMeshes()[0];
    root.setEnabled(false);
    calendarRoot.current = root as CalendarMeshType;
    faceMaterial.current = scene.getMeshByName('face')?.material as PBRMaterial;

    actionManager.current = createDrawerActionManager(scene);
    drawerStatesTextures.current = createDrawerStateTextures(scene);

    const overlays = getOverlays(scene);

    overlays.forEach((overlay) => {
      const overlayMaterial = overlay.material as PBRMaterial;
      if (!overlayMaterial.albedoTexture) return;
      overlayMaterial.albedoTexture.hasAlpha = true;
      overlayMaterial.transparencyMode = 3;
      overlayMaterial.zOffsetUnits = -10;
      overlay.position.y += 0.0;
      overlay.isVisible = false;
      overlay.isPickable = false;
    });

    const drawers = getDrawers(scene);

    drawers.forEach((drawer) => {
      drawer.isPickable = true;
      drawer.material = scene.getMaterialByName('momo')?.clone(`${drawer.name}_material`) as PBRMaterial;
      setDrawerState(drawer, DrawerState.DISABLED);
    });

    if (day.current === undefined) day.current = 0;

    for (let i = day.current + 1; i < drawers.length; i++) {
      setDrawerState(drawers[i], DrawerState.LOCKED);
    }
    setDrawerState(drawers[day.current], DrawerState.ENABLED);
  };

  return (
    <Scene
      clearColor={new Color4(0.043, 0.043, 0.1, 1)}
      onSceneMount={(scene: SceneEventArgs) => {
        setupScene(scene);
      }}
    >
      <arcRotateCamera
        name="arcRotateCamera"
        target={new Vector3(0, 6, 0)}
        alpha={-Math.PI / 2}
        beta={Math.PI / 2 - 0.05}
        radius={1000}
        lowerBetaLimit={Math.PI / 2 - 0.05}
        upperBetaLimit={Math.PI / 2 - 0.05}
        lowerRadiusLimit={10}
        minZ={0.01}
        panningSensibility={0}
        onCreated={(camera: ArcRotateCamera) => {
          setupCamera(camera);
        }}
      />
      <hemisphericLight name="hemisphericLight" direction={new Vector3(0, 1, 0)} specular={new Color3(0, 0, 0)} />

      <Suspense fallback={<plane name="plane" size={0.2}></plane>}>
        {loadCalendar && (
          <Model
            name="calendar"
            sceneFilename="calendar.glb"
            rootUrl="./scenes_assets/Calendar/mesh/"
            onCreated={(calendar: AbstractMesh) => {
              setupCalendar(calendar as CalendarMeshType);
            }}
          ></Model>
        )}
      </Suspense>
    </Scene>
  );
}

export default Calendar;

import { useEffect, useState } from 'react';
import { Engine } from 'react-babylonjs';
import Calendar from '../scenes/Calendar/Calendar';
import { CanvasWrapper } from './RenderingCanvas.css';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
import Snowfall from 'react-snowfall';
import PoweredLogo from '../components/PoweredLogo/PoweredLogo';
import snowflakeImage from './assets/img/snowflake.png';

export default function RenderingCanvas() {
  const [loader, setLoader] = useState<boolean>(true);
  const [opacity, setOpacity] = useState<number>(1);
  const snowflake = document.createElement('img');
  snowflake.src = snowflakeImage;

  useEffect(() => {
    if (opacity === 0) {
      setTimeout(() => setLoader(false), 2000);
    }
  }, [opacity]);

  return (
    <CanvasWrapper>
      {loader && <LoadingScreen opacity={opacity} />}
      {!loader && <Snowfall color="white" snowflakeCount={350} radius={[1, 20]} images={[snowflake]} />}
      <Engine antialias adaptToDeviceRatio canvasId="babylonJS">
        <Calendar setOpacity={setOpacity} />
      </Engine>
      <PoweredLogo />
      {/* <audio src="./scenes_assets/Calendar/audio/santa.mp3" autoPlay muted></audio> */}
    </CanvasWrapper>
  );
}
